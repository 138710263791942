import React, { useState, useEffect, useContext } from 'react';
import { Popconfirm } from 'antd';
import { withRouter } from 'react-router';
import { fetchEmployees, removeEmployee } from '../../services/api.service';
import { useModal } from 'react-modal-hook';
import { default as MaterialTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { EmptyTable } from '../common/emptytable';
import { EditEmployeeModal } from './edit.employee.modal';
import { waitFor } from '../../utils/utils';
import { PageSpinner } from '../common/pagespinner';
import { NotificationContext } from '../../notifications.context';
import { UserContext } from '../../user.context';

const EmployeesTable = ({ match }) => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const { notify } = useContext(NotificationContext);
  const { user } = useContext(UserContext);

  const clientId =
    match && match.params && match.params.id ? match.params.id : user.id;

  const fetchData = async () => {
    try {
      setEmployees([]);
      setLoading(true);
      await waitFor(300);
      const employees = await fetchEmployees(clientId);
      setEmployees(employees);
    } catch (err) {
      notify(err.message || err, 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [showModal, hideModal] = useModal(
    () => (
      <EditEmployeeModal
        hideModal={() => {
          hideModal();
          setEditingEmployee(null);
        }}
        notify={notify}
        clientId={clientId}
        data={editingEmployee}
        onDataSaved={data => {
          setEditingEmployee(null);
          if (data) {
            setLoading(true);
            fetchData().then(() => notify('Employee updated'));
          }
        }}
      />
    ),
    [editingEmployee, employees],
  );

  const onClickEdit = proxy => {
    setEditingEmployee(proxy);
    showModal();
  };

  const onDeleteEmployee = async employee => {
    try {
      await removeEmployee(employee.id);
      setEmployees(employees.filter(p => p.id !== employee.id));
      notify('Employee removed');
    } catch (err) {
      notify(err, 'error');
    }
  };

  return (
    <React.Fragment>
      <div className="section-head">
        <h1 className="page-heading">Employees</h1>
        <Button variant="outlined" color="primary" onClick={showModal}>
          Add Employee
        </Button>
      </div>
      <Paper
        style={{ marginTop: 24 }}
        square={true}
        elevation={1}
        className="tableContainer">
        <MaterialTable className="material-table">
          <TableHead>
            <TableRow>
              <TableCell className="smallCell">Id</TableCell>
              <TableCell align="right">FirstName</TableCell>
              <TableCell align="right">LastName</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Address</TableCell>
              <TableCell align="right">Session phone</TableCell>
              <TableCell align="right" className="smallCell">
                Forwarding phone
              </TableCell>
              <TableCell align="right">Site login</TableCell>
              <TableCell align="right" style={{ width: 200 }}>
                Site password
              </TableCell>
              <TableCell align="right">Chat Room</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map(row => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  classes={{ root: 'smallCell' }}
                  className="smallCell">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.firstName}</TableCell>
                <TableCell align="right">{row.lastName}</TableCell>
                <TableCell align="right">
                  <strong>{row.email}</strong>
                </TableCell>
                <TableCell align="right">
                  {row.address && <span>{row.address}, &nbsp;</span>}
                  {row.city && <span>{row.city}, &nbsp;</span>}
                  {row.state && <span>{row.state}, &nbsp;</span>}
                  {row.zip && <span>{row.zip}</span>}
                </TableCell>
                <TableCell align="right">{row.sessionPhone}</TableCell>
                <TableCell align="right" classes={{ root: 'smallCell' }}>
                  {row.forwardingPhone}
                </TableCell>
                <TableCell align="right">{row.siteLogin}</TableCell>
                <TableCell align="right">{row.sitePassword}</TableCell>
                <TableCell align="right">{row.chatRoom}</TableCell>
                <TableCell align="right">
                  <div className="matTableActions">
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onClickEdit(row)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <div onClick={e => e.stopPropagation()}>
                      <Popconfirm
                        placement="left"
                        title="are you sure？"
                        onConfirm={() => onDeleteEmployee(row)}
                        okText="delete"
                        cancelText="cancel">
                        <IconButton aria-label="Delete">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Popconfirm>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
        {loading && <PageSpinner />}
        {!loading && (!employees || !employees.length) ? <EmptyTable /> : null}
      </Paper>
    </React.Fragment>
  );
};

export default withRouter(EmployeesTable);
