import React, { useContext } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { BaseModal } from '../common/modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { trimAllValues } from '../../utils/utils';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { addOrSaveZone } from '../../services/api.service';
import { NotificationContext } from '../../notifications.context';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const styles = () => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 12,
    paddingTop: 32,
  },
  footerButton: {
    minWidth: 160,
    marginLeft: 12,
  },
  textField: {
    margin: '8px !important',
    width: 198,
  },
  select: {
    width: 198,
    margin: '8px 8px',
  },
  checkboxField: {
    width: '100%',
  },
});

const validate = values => {
  const errors = {};
  if (!values.zone) {
    errors.zone = 'Zone name is required';
  }
  if (!values.accountId) {
    errors.accountId = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  if (!values.type) {
    errors.type = 'Required';
  }
  return errors;
};

export const ZONE_TYPES = {
  DATA_CENTER: 'Data Center',
  RESIDENTIAL_STATIC: 'Residential Static',
  // RESIDENTIAL_ROTATING_SHARED: 'Residential Rotating Shared',
  RESIDENTIAL_ROTATING_EXCLUSIVE: 'Residential Rotating Exclusive',
  MOBILE: 'Mobile Exclusive',
};

export const EditZoneModalComponent = ({
  hideModal,
  classes,
  data = {},
  onDataSaved,
}) => {
  const { notify } = useContext(NotificationContext);
  const { form, handleSubmit, submitting } = useForm({
    initialValues: { ...data },
    onSubmit: async values => {
      try {
        const newZone = await addOrSaveZone(trimAllValues(values));
        onDataSaved(newZone);
        hideModal();
      } catch (err) {
        notify(err.message || err, 'error');
      }
    },
    validate,
  });

  const zone = useField('zone', form);
  const accountId = useField('accountId', form);
  const password = useField('password', form);
  const type = useField('type', form);
  const allowCityGeoLocation = useField('allowCityGeoLocation', form);
  const shareZoneToProxyPool = useField('shareZoneToProxyPool', form);

  const getFieldError = field =>
    field.meta.touched && field.meta.error ? field.meta.error : false;
  return (
    <BaseModal width={500} onClose={hideModal}>
      <React.Fragment>
        <h2>Edit Zone</h2>
        <form onSubmit={handleSubmit}>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(zone)}>
            <InputLabel htmlFor="zone">Zone</InputLabel>
            <Input id="zone" {...zone.input} />
            {getFieldError(zone) && (
              <FormHelperText id="zone">{getFieldError(zone)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(accountId)}>
            <InputLabel htmlFor="accountId">Zone AccountId</InputLabel>
            <Input id="accountId" {...accountId.input} />
            {getFieldError(accountId) && (
              <FormHelperText id="accountId">
                {getFieldError(accountId)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(password)}>
            <InputLabel htmlFor="password">Zone Password</InputLabel>
            <Input id="password" {...password.input} />
            {getFieldError(password) && (
              <FormHelperText id="password">
                {getFieldError(password)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(type)}>
            <InputLabel htmlFor="type">Zone type</InputLabel>
            <Select {...type.input} id="type">
              {Object.entries(ZONE_TYPES).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  {value}
                </MenuItem>
              ))}
            </Select>
            {getFieldError(type) && (
              <FormHelperText id="type">{getFieldError(type)}</FormHelperText>
            )}
          </FormControl>
          <div>
            <FormControl
              disabled={submitting}
              className={classes.checkboxField}
              error={!!getFieldError(shareZoneToProxyPool)}>
              <FormControlLabel
                disabled={submitting}
                control={
                  <Checkbox
                    {...shareZoneToProxyPool.input}
                    value={`${shareZoneToProxyPool.input.value}`}
                    checked={shareZoneToProxyPool.input.value}
                    color="primary"
                  />
                }
                label="Share Zone to proxy pool"
              />
              {getFieldError(shareZoneToProxyPool) && (
                <FormHelperText id="shareZoneToProxyPool">
                  {getFieldError(shareZoneToProxyPool)}
                </FormHelperText>
              )}
            </FormControl>
          </div>
          <div>
            <FormControl
              disabled={submitting}
              className={classes.checkboxField}
              error={!!getFieldError(allowCityGeoLocation)}>
              <FormControlLabel
                disabled={submitting}
                control={
                  <Checkbox
                    {...allowCityGeoLocation.input}
                    value={`${allowCityGeoLocation.input.value}`}
                    checked={allowCityGeoLocation.input.value}
                    color="primary"
                  />
                }
                label="Allow City GeoLocation"
              />
              {getFieldError(allowCityGeoLocation) && (
                <FormHelperText id="allowCityGeoLocation">
                  {getFieldError(allowCityGeoLocation)}
                </FormHelperText>
              )}
            </FormControl>
          </div>

          <section className={classes.modalFooter}>
            <Button
              disabled={submitting}
              type="button"
              className={classes.footerButton}
              onClick={hideModal}>
              Close
            </Button>
            <Button
              disabled={submitting}
              className={classes.footerButton}
              variant="outlined"
              color="primary"
              type="submit">
              Save
            </Button>
          </section>
        </form>
      </React.Fragment>
    </BaseModal>
  );
};

export const EditZoneModal = withStyles(styles)(EditZoneModalComponent);
