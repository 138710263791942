import React, { useState, useEffect } from 'react';
import { tryRestoreAuthorization } from './services/api.service';
import { PageSpinner } from './components/common/pagespinner';

export const UserContext = React.createContext({});

export const UserContextProvider = props => {
  const [user, setUser] = useState({});
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    tryRestoreAuthorization()
      .then(setUser)
      .finally(() => setAuthLoading(false));
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {authLoading ? <PageSpinner /> : props.children}
    </UserContext.Provider>
  );
};
