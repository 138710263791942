import React, { useState, useEffect, useContext } from 'react';
import { Popconfirm } from 'antd';
import {
  addUserAgent,
  fetchUserAgents,
  removeUserAgent,
  updateUserAgentStatus,
} from '../../services/api.service';
import './settings.styles.scss';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { default as MaterialTable } from '@material-ui/core/Table';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { NotificationContext } from '../../notifications.context';

export const UserAgents = () => {
  const [userAgents, setUserAgents] = useState([]);
  const [newUserAgent, setNewUserAgent] = useState('');
  const { notify } = useContext(NotificationContext);

  useEffect(() => {
    fetchUserAgents().then(setUserAgents);
  }, []);

  const changeUserAgentStatus = record => {
    updateUserAgentStatus(record.id, !record.isActive);
    setUserAgents(
      userAgents.map(ua => ({
        ...ua,
        isActive: ua.id === record.id ? !ua.isActive : ua.isActive,
      })),
    );
  };

  const onAddUserAgent = () => {
    if (!newUserAgent || !newUserAgent.trim()) {
      return;
    }
    addUserAgent({ name: newUserAgent })
      .then(createdUA => {
        setUserAgents([...userAgents, createdUA]);
        notify('New useragent added');
      })
      .catch(err => notify(err, 'error'))
      .finally(() => setNewUserAgent(''));
  };

  const onDeleteConfirm = userAgent => {
    removeUserAgent(userAgent.id).then(() => {
      setUserAgents(userAgents.filter(item => item.id !== userAgent.id));
      notify('Useragent removed');
    });
  };

  return (
    <Paper square={true} elevation={1}>
      <MaterialTable className="material-table settings-table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Is Active</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {userAgents.map(row => (
            <TableRow key={row.id}>
              <TableCell align="right">
                <Checkbox
                  color="primary"
                  className="useragent-checkbox"
                  onChange={() => changeUserAgentStatus(row)}
                  checked={!!row.isActive}
                />
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">
                <div className="matTableActions">
                  <div onClick={e => e.stopPropagation()}>
                    <Popconfirm
                      placement="left"
                      title="are you sure？"
                      onConfirm={() => onDeleteConfirm(row)}
                      okText="delete"
                      cancelText="cancel">
                      <IconButton aria-label="Delete">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Popconfirm>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MaterialTable>
      <div className="add-useragent-form">
        <Input
          placeholder="Add new useragent"
          style={{ width: '100%' }}
          value={newUserAgent}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onAddUserAgent();
            }
          }}
          onChange={e => setNewUserAgent(e.target.value)}
        />
        <Button
          type="primary"
          variant={'outlined'}
          className="add-useragent-button"
          onClick={onAddUserAgent}>
          Add useragent
        </Button>
      </div>
    </Paper>
  );
};
