import React, { useEffect, useState } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { waitFor } from '../../utils/utils';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { fetchSettings, saveSettings } from '../../services/api.service';
import { PageSpinner } from '../common/pagespinner';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formInner: {
    display: 'flex',
    alignItems: 'baseline',
  },
  textField: {
    width: 140,
    marginRight: 12,
  },
  heading: {
    fontSize: 16,
    fontWeight: 500,
  },
};

export const SettingsValuesComponent = ({ classes, settings }) => {
  const { form, handleSubmit, submitting } = useForm({
    initialValues: settings,
    onSubmit: async values => {
      await waitFor(300);
      try {
        saveSettings(values);
      } catch (err) {
        console.log('err', err);
      }
    },
    validate,
  });
  const coolDownLimit = useField('coolDownLimit', form);
  const reuseLimit = useField('reuseLimit', form);

  const getFieldError = field =>
    field.meta.touched && field.meta.error ? field.meta.error : false;

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <h4 className={classes.heading}>Setting values</h4>
      <div className={classes.formInner}>
        <FormControl
          style={{ minWidth: 200 }}
          disabled={submitting}
          className={classes.textField}
          error={!!getFieldError(coolDownLimit)}>
          <InputLabel htmlFor="coolDownLimit">
            Cooldown limit (minutes)
          </InputLabel>
          <Input id="coolDownLimit" {...coolDownLimit.input} type="number" />
          {getFieldError(coolDownLimit) && (
            <FormHelperText id="coolDownLimit">
              {getFieldError(coolDownLimit)}
            </FormHelperText>
          )}
        </FormControl>
        <FormControl
          disabled={submitting}
          className={classes.textField}
          error={!!getFieldError(reuseLimit)}>
          <InputLabel htmlFor="reuseLimit">Reuse limit</InputLabel>
          <Input id="reuseLimit" type="number" {...reuseLimit.input} />
          {getFieldError(reuseLimit) && (
            <FormHelperText id="reuseLimit">
              {getFieldError(reuseLimit)}
            </FormHelperText>
          )}
        </FormControl>
        <Button
          disabled={submitting}
          className={classes.footerButton}
          variant="outlined"
          color="primary"
          type="submit">
          Save
        </Button>
      </div>
    </form>
  );
};

const SettingValuesWrapper = props => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    fetchSettings().then(settings => {
      setSettings(settings);
    });
  }, []);

  return settings ? (
    <SettingsValuesComponent {...props} settings={settings} />
  ) : (
    <PageSpinner />
  );
};

export const SettingsValues = withStyles(styles)(SettingValuesWrapper);

function validate({ coolDownLimit, reuseLimit }) {
  const errors = {};
  if (!/^\d+$/.test(coolDownLimit)) {
    errors.coolDownLimit = 'Invalid value';
  }
  if (!/^\d+$/.test(reuseLimit)) {
    errors.reuseLimit = 'Invalid value';
  }
  return errors;
}
