import React from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { BaseModal } from '../common/modal';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { trimAllValues, waitFor } from '../../utils/utils';
import { addOrSaveProxy } from '../../services/api.service';

const styles = () => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 12,
    paddingTop: 32,
  },
  footerButton: {
    minWidth: 160,
    marginLeft: 12,
  },
  textField: {
    margin: '8px !important',
    width: 198,
  },
  select: {
    width: 198,
    margin: '8px 8px',
  },
});

const validate = values => {
  const errors = {};
  if (!values.ip) {
    errors.ip = 'Required';
  }
  if (!values.port) {
    errors.port = 'Required';
  }
  return errors;
};

const initialValues = {};

export const EditProxyModalComponent = ({
  hideModal,
  classes,
  data = {},
  onDataSaved,
}) => {
  const { form, handleSubmit, submitting } = useForm({
    initialValues: { ...initialValues, ...data },
    onSubmit: async values => {
      await waitFor(1000);
      try {
        const rez = await addOrSaveProxy(trimAllValues(values));
        hideModal();
        onDataSaved(rez);
      } catch (err) {
        console.log('err', err);
      }
    },
    validate,
  });

  const ip = useField('ip', form);
  const port = useField('port', form);
  const user = useField('user', form);
  const password = useField('password', form);
  const status = useField('status', form);
  const dataCenter = useField('dataCenter', form);
  const reusedCount = useField('reusedCount', form);

  const getFieldError = field =>
    field.meta.touched && field.meta.error ? field.meta.error : false;

  return (
    <BaseModal width={500} onClose={hideModal}>
      <React.Fragment>
        <h2>Edit Proxy</h2>
        <form onSubmit={handleSubmit}>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(ip)}>
            <InputLabel htmlFor="ip">Ip</InputLabel>
            <Input id="ip" {...ip.input} />
            {getFieldError(ip) && (
              <FormHelperText id="ip">{getFieldError(ip)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(port)}>
            <InputLabel htmlFor="port">Port</InputLabel>
            <Input id="port" type="number" {...port.input} />
            {getFieldError(port) && (
              <FormHelperText id="port">{getFieldError(port)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(user)}>
            <InputLabel htmlFor="user">User</InputLabel>
            <Input id="user" {...user.input} />
            {getFieldError(user) && (
              <FormHelperText id="user">{getFieldError(user)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(password)}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password" {...password.input} />
            {getFieldError(password) && (
              <FormHelperText id="password">
                {getFieldError(password)}
              </FormHelperText>
            )}
          </FormControl>
          {data && (
            <FormControl
              disabled={submitting}
              className={classes.textField}
              error={!!getFieldError(status)}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <Select {...status.input} id="type">
                <MenuItem value={'Good'}>Good</MenuItem>
                <MenuItem value={'Cooling'}>Cooling</MenuItem>
                <MenuItem value={'Unavailable'}>Unavailable</MenuItem>
              </Select>
              {getFieldError(status) && (
                <FormHelperText id="status">
                  {getFieldError(status)}
                </FormHelperText>
              )}
            </FormControl>
          )}
          {data && (
            <FormControl
              disabled={submitting}
              className={classes.textField}
              error={!!getFieldError(reusedCount)}>
              <InputLabel htmlFor="reusedCount">
                Time Reported Invalid
              </InputLabel>
              <Input id="password" {...reusedCount.input} type="number" />
              {getFieldError(reusedCount) && (
                <FormHelperText id="reusedCount">
                  {getFieldError(reusedCount)}
                </FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(dataCenter)}>
            <InputLabel htmlFor="dataCenter">Datacenter</InputLabel>
            <Input id="dataCenter" {...dataCenter.input} />
            {getFieldError(dataCenter) && (
              <FormHelperText id="dataCenter">
                {getFieldError(dataCenter)}
              </FormHelperText>
            )}
          </FormControl>
          <section className={classes.modalFooter}>
            <Button
              disabled={submitting}
              type="button"
              className={classes.footerButton}
              onClick={hideModal}>
              Close
            </Button>
            <Button
              disabled={submitting}
              className={classes.footerButton}
              variant="outlined"
              color="primary"
              type="submit">
              Save
            </Button>
          </section>
        </form>
      </React.Fragment>
    </BaseModal>
  );
};

export const EditProxyModal = withStyles(styles)(EditProxyModalComponent);
