import React, { useState } from 'react';
import { MySnackbarContentWrapper } from './components/common/snackbar';

export const NotificationContext = React.createContext({
  notify: () => {},
});

export const NotificationContextProvider = props => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [type, setType] = useState('info');

  const notify = (text, type = 'info') => {
    setText(text);
    setType(type);
    setShow(true);
  };

  const onClose = () => {
    setShow(false);
    setText('');
    setType('info');
  };

  return (
    <NotificationContext.Provider value={{ notify }}>
      {props.children}
      {show && (
        <MySnackbarContentWrapper
          variant={type}
          message={text}
          onClose={onClose}
        />
      )}
    </NotificationContext.Provider>
  );
};
