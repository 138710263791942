import React from 'react';
import { UserAgents } from './useragents';
import { MachineTypes } from './machinetypes';
import { SettingsValues } from './setting.values';
import { ExcludedDomains } from './excluded.domains';

export const Settings = () => (
  <React.Fragment>
    <h1 className="page-heading">Settings</h1>
    <section className="settings__topSection">
      <SettingsValues />
      <ExcludedDomains />
    </section>

    <section className="settings-tables" style={{ margin: '24px 0 0 0' }}>
      <section style={{ width: 600, maxWidth: 600 }}>
        <h2 style={{ padding: '0 0 12px' }}>UserAgents</h2>
        <UserAgents />
      </section>
      <section style={{ marginLeft: 24, width: 600, maxWidth: 600 }}>
        <h2 style={{ padding: '0 0 12px' }}>Machine types</h2>
        <MachineTypes />
      </section>
    </section>
  </React.Fragment>
);
