import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

const styles = {
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: '#d32f2f',
  },
  info: {
    backgroundColor: '#2196f3',
  },
  warning: {
    backgroundColor: amber[700],
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
};

export const MySnackbarContentWrapper = ({
  message,
  onClose,
  variant = 'info',
  ...other
}) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
      duration: 115000,
    }}
    open={true}
    autoHideDuration={125000}
    onClose={onClose}>
    <SnackbarContent
      style={{ ...styles[variant] }}
      aria-describedby="client-snackbar"
      message={message}
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onClose}>
          <CloseIcon />
        </IconButton>,
      ]}
      {...other}
    />
  </Snackbar>
);
