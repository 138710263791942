import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory as createHistory } from 'history';
import { ModalProvider } from 'react-modal-hook';
import { LoginPage } from './pages/login/login.page';
import MainPage from './pages/main/main.page';
import { RootWrapper } from './root.wrapper';

export const history = createHistory();

const NoMatch = () => <h2>Not found</h2>;

export const Routes = () => {
  return (
    <RootWrapper>
      <Router history={history}>
        <ModalProvider>
          <React.Fragment>
            <main className="main-section">
              <Switch>
                <Route path={'/login/'} component={LoginPage} />
                <Route path="/" component={MainPage} />
                <Route component={NoMatch} />
              </Switch>
            </main>
          </React.Fragment>
        </ModalProvider>
      </Router>
    </RootWrapper>
  );
};

export default Routes;
