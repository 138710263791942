import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AppBar, Toolbar, Button } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { logout } from '../../services/api.service';
import { UserContext } from '../../user.context';

const styles = {
  root: {
    flexGrow: 1,
  },
  logo: {
    color: 'white',
    margin: 0,
    '&:hover': {
      color: 'white',
    },
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  toolbarButton: {
    marginLeft: 0,
    marginRight: 0,
    borderRadius: 0,
    padding: '8px 24px',
    color: 'white !important',
    '&.active': {
      backgroundColor: 'rgba(255,255,255,0.1)',
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: 3,
        width: '100%',
        backgroundColor: 'white',
      },
    },
  },
  rightBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  userBlock: {
    fontSize: 12,
    fontWeight: 500,
    paddingRight: 12,
  },
  headerNav: {
    minHeight: 64,
    display: 'flex',
  },
};

function ButtonAppBar(props) {
  const { classes } = props;
  const { user, setUser } = useContext(UserContext);

  const makeLogout = () => {
    setUser(null);
    logout();
  };

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbar}>
          <Link to={''}>
            <h1 className={classes.logo}>Private Tabs - Admin Panel</h1>
          </Link>
          {user && user.role === 'admin' ? (
            <nav className={classes.headerNav}>
              <Button
                color="inherit"
                className={classes.toolbarButton}
                component={props => <NavLink exact to="/" {...props} />}>
                Clients
              </Button>
              <Button
                color="inherit"
                className={classes.toolbarButton}
                component={props => <NavLink to="/proxies" {...props} />}>
                Proxy Pool
              </Button>
              <Button
                color="inherit"
                className={classes.toolbarButton}
                component={props => <NavLink to="/settings" {...props} />}>
                Settings
              </Button>
            </nav>
          ) : null}
          <div className={classes.rightBlock}>
            <span className={classes.userBlock}>{user ? user.name : ''}</span>
            <Button color="inherit" onClick={() => makeLogout()}>
              Logout
              <ExitToApp style={{ paddingLeft: 8 }} />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default withStyles(styles)(ButtonAppBar);
