import ax from 'axios';
import moment from 'moment';
import { history } from '../routes';
import { waitFor } from '../utils/utils';
import { SERVER_URL } from '../constants';

const axios = ax.create({
  baseURL: SERVER_URL,
});

// Map 'data' prop after each response
axios.interceptors.response.use(
  response => {
    if (response.status < 300) {
      return response.data;
    }
    return response;
  },
  error => {
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('token');
      removeAuthToken();
      history.push('/login');
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  },
);

export const setAuthToken = token => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const removeAuthToken = () =>
  (axios.defaults.headers.common['Authorization'] = '');

export const tryRestoreAuthorization = async () => {
  await waitFor(300);
  const oldToken = localStorage.getItem('token');
  if (!oldToken) {
    history.push('/login');
    return;
  }
  try {
    setAuthToken(oldToken);
    return await verifyToken();
  } catch (err) {
    removeAuthToken();
    history.push('/login');
  }
};

export const login = (login, password) =>
  axios.post('/auth/login', { login, password }).then(user => {
    const { token } = user;
    localStorage.setItem('token', token);
    setAuthToken(token);
    history.push('/');
    return user;
  });

export const logout = () => {
  localStorage.removeItem('token');
  history.push('/login');
};

export const verifyToken = () => axios.post('/auth/verify_token');

export const DATE_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
export const SHORT_DATE_FORMAT = 'hh:mm:ss A';

export const fetchClients = () =>
  axios.get('/admin/clients').then(clients =>
    clients.map(client => ({
      ...client,
      lastUsed: client.lastUsed
        ? moment
            .utc(client.lastUsed)
            .local()
            .format(DATE_FORMAT)
        : '',
    })),
  );

export const fetchProxies = () =>
  axios.get('/admin/proxies').then(proxies =>
    proxies.map(proxy => ({
      ...proxy,
      lastUsed: proxy.lastUsed
        ? moment
            .utc(proxy.lastUsed)
            .local()
            .format(DATE_FORMAT)
        : '',
    })),
  );

export const fetchEmployees = clientId =>
  axios.get(`/admin/employees?clientId=${clientId}`);

export const addOrSaveProxy = proxy => {
  delete proxy.created_at;
  delete proxy.updated_at;
  delete proxy.lastUsed;
  delete proxy.cooldownend;
  delete proxy.assigned;
  return axios.post('/admin/proxies', proxy);
};

export const fetchZones = () => axios.get('/admin/zones');

export const removeZone = zoneId => axios.delete(`/admin/zones/${zoneId}`);

export const addOrSaveZone = zone => {
  delete zone.expanded;
  delete zone.created_at;
  delete zone.updated_at;
  return axios.post('/admin/zones', zone);
};

export const checkZoneName = zoneName =>
  axios
    .get(`/admin/zones/check_zone_name?zoneName=${zoneName}`)
    .then(({ success }) => {
      return success;
    })
    .catch(() => false);

export const clearBadProxiesForZone = zone =>
  axios.post('/admin/zones/clear_bad_proxies', { id: zone.id });

export const removeProxy = proxyId => axios.delete(`/admin/proxies/${proxyId}`);

export const addOrUpdateClient = client => {
  // TODO Make this check on server
  delete client.created_at;
  delete client.updated_at;
  delete client.lastUsed;
  delete client.apiKey;
  delete client.isOnline;
  delete client.tabsInUse;
  return axios.post('/admin/clients', client);
};

export const addOrUpdateEmployee = employee => {
  delete employee.created_at;
  delete employee.updated_at;
  delete employee.websites;
  return axios.post('/admin/employees', employee);
};

export const removeEmployee = employeeId =>
  axios.delete(`/admin/employees/${employeeId}`);

export const removeClient = clientId =>
  axios.delete(`/admin/clients/${clientId}`);

export const fetchUserAgents = () => axios.get('/admin/useragents');

export const addUserAgent = userAgentData =>
  axios.post('/admin/useragents', userAgentData);

export const removeUserAgent = id => axios.delete(`/admin/useragents/${id}`);

export const updateUserAgentStatus = (id, status) =>
  axios.patch(`/admin/useragents/${id}`, { status });

export const fetchMachineTypes = () => axios.get('/admin/machines');

export const addMachineType = userAgentData =>
  axios.post('/admin/machines', userAgentData);

export const updateMachineTypeStatus = (id, status) =>
  axios.patch(`/admin/machines/${id}`, { status });

export const removeMachineType = id => axios.delete(`/admin/machines/${id}`);

export const fetchSettings = () => axios.get('/admin/settings');

export const saveSettings = settings =>
  axios.post('/admin/settings', {
    coolDownLimit: settings.coolDownLimit,
    reuseLimit: settings.reuseLimit,
  });

export const forceDisconnectClient = apiKey =>
  axios.post('/admin/clients/disconnect', { apiKey });

export const fetchExcludedDomainList = () =>
  axios.get('/admin/excluded_domains');

export const saveExcludedDomainList = domains =>
  axios.post('/admin/excluded_domains', domains);
