import React, { useContext, useState, useEffect } from 'react';
import { Popconfirm } from 'antd';
import moment from 'moment';
import classnames from 'classnames';
import { default as MaterialTable } from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { EmptyTable } from '../common/emptytable';
import { PageSpinner } from '../common/pagespinner';
import Button from '@material-ui/core/Button';
import { useModal } from 'react-modal-hook';
import {
  SHORT_DATE_FORMAT,
  fetchZones,
  removeZone,
  clearBadProxiesForZone,
} from '../../services/api.service';
import { NotificationContext } from '../../notifications.context';
import { EditZoneModal } from './edit.zone.modal';

export const ZONE_TYPES_MAP = {
  DATA_CENTER: 'Data Center',
  RESIDENTIAL_STATIC: 'Residential Static',
  RESIDENTIAL_ROTATING_EXCLUSIVE: 'Residential Rotating Exclusive',
  MOBILE: 'Mobile Exclusive',
};

export const ZonesTable = () => {
  const [zones, setZones] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingZone, setEditingZone] = useState(null);
  const { notify } = useContext(NotificationContext);

  const [showModal, hideModal] = useModal(
    () => (
      <EditZoneModal
        hideModal={() => {
          hideModal();
          setEditingZone(null);
        }}
        data={editingZone}
        onDataSaved={async zone => {
          try {
            setEditingZone(null);
            if (zone) {
              setLoading(true);
              setZones([]);
              const newZones = await fetchZones();
              setZones(newZones);
              notify('Zones updated');
            }
          } catch (err) {
            notify(err.message || err, 'error');
          } finally {
            setLoading(false);
          }
        }}
      />
    ),
    [editingZone, zones],
  );

  useEffect(() => {
    fetchZones()
      .then(rez => setZones(rez.map(zone => ({ ...zone, expanded: true }))))
      .catch(err => notify(err.message || `Cant fetch zones - ${err}`, 'error'))
      .finally(() => setLoading(false));
  }, []);

  const onClickEdit = zone => {
    setEditingZone(zone);
    showModal();
  };

  const clearBadProxies = zone => {
    setZones(
      zones.map(z => ({
        ...z,
        badGips: z.id === zone.id ? [] : z.badGips,
      })),
    );
    clearBadProxiesForZone(zone)
      .then(() => {
        notify('Reported bad proxies removed');
      })
      .catch(err => notify(err.message || 'Error occurred', 'error'));
  };

  const onClickExpand = row =>
    setZones(
      zones.map(zone => ({
        ...zone,
        expanded: zone.id === row.id ? !row.expanded : row.expanded,
      })),
    );

  const onClickDelete = async zone => {
    try {
      await removeZone(zone.id);
      setZones(zones.filter(z => z.id !== zone.id));
      notify('Zone has been removed');
    } catch (err) {
      notify(err, 'error');
    }
  };

  const updateZones = () => {
    setZones([]);
    setLoading(true);
    fetchZones()
      .then(rez => setZones(rez.map(zone => ({ ...zone, expanded: true }))))
      .catch(err => notify(err.message || `Cant fetch zones - ${err}`, 'error'))
      .finally(() => setLoading(false));
  };

  return <React.Fragment>
      <div className="section-head">
        <h1 className="page-heading">Luminati Zones</h1>

        <div className="section-headRight">
          <Button onClick={updateZones} style={{ marginRight: 24 }} disabled={loading}>
            Update zones
          </Button>
          <Button variant="outlined" color="primary" onClick={showModal}>
            Add Luminati Zone
          </Button>
        </div>
      </div>
      <Paper style={{ marginTop: 0, marginBottom: 40 }} square={true} elevation={1} className="tableContainer">
        <MaterialTable className="material-table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="left">Zone</TableCell>
              <TableCell align="right">Zone AccountId</TableCell>
              <TableCell align="right">Zone Password</TableCell>
              <TableCell align="right">Zone type</TableCell>
              <TableCell align="right">Available Vips</TableCell>
              <TableCell align="right">Allow City GeoLocation</TableCell>
              <TableCell align="right">Share Zone To Proxy Pool</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {zones.map(row => <React.Fragment key={row.id}>
                <TableRow key={row.id}>
                  <TableCell className="zoneTableCell" component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="left">
                    <strong>{row.zone}</strong>
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    {row.accountId}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    {row.password}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    {ZONE_TYPES_MAP[row.type]}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    {row.availableVips}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    {row.allowCityGeoLocation ? <DoneIcon style={{ color: '#07ca10' }} /> : <NotInterestedIcon style={{ color: '#ca3d0f' }} />}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    {' '}
                    {row.shareZoneToProxyPool ? <DoneIcon style={{ color: '#07ca10' }} /> : <NotInterestedIcon style={{ color: '#ca3d0f' }} />}
                  </TableCell>
                  <TableCell className="zoneTableCell" align="right">
                    <div className="matTableActions">
                      <IconButton aria-label="Delete" onClick={() => onClickEdit(row)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <div onClick={e => e.stopPropagation()}>
                        <Popconfirm placement="left" title="are you sure？" onConfirm={() => onClickDelete(row)} okText="delete" cancelText="cancel">
                          <IconButton aria-label="Delete">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Popconfirm>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>

                <TableRow key={`${row.id}_details`}>
                  <TableCell />
                  <TableCell colSpan={8}>
                    {row.error && <div className="zoneDetails__errorBlock">
                        <div className="zoneDetails__errorHeader">
                          Zone error:{' '}
                        </div>
                        <div className="zoneDetails__error">{row.error}</div>
                      </div>}

                    <div className="zoneDetails">
                      <div className="zoneDetailsInfo">
                        <div className="zoneDetailsInfo___title">
                          Used vips -
                        </div>
                        <div className="zoneDetailsInfo___value">
                          {row.vipsTotalUsed}/{row.availableVips}
                        </div>
                      </div>
                      <div className="zoneDetailsInfo">
                        <div className="zoneDetailsInfo___title">
                          Repored bad vips -
                        </div>
                        <div className="zoneDetailsInfo___value">
                          {Object.keys(row.badGips || {}).length}
                        </div>
                      </div>
                      <Button size="small" className="zoneDetails__toggleButton" onClick={() => onClickExpand(row)}>
                        {row.expanded ? <ArrowUpIcon style={{ color: '#999' }} /> : <ArrowDownIcon style={{ color: '#999' }} />}
                        Toggle Details
                      </Button>
                    </div>

                    {row.expanded && <div className="zoneStats">
                        <div className="zoneStats__left">
                          <div className="zoneStats__header">Vips in use</div>
                          {row.vipUsage && Object.keys(row.vipUsage).length ? <div className="zoneStats__gipsContainer">
                              {Object.entries(row.vipUsage).map(
                                ([vip, client]) => (
                                  <div className="zoneStats__gip">
                                    <span className="zoneStats__gipTitle">
                                      {vip} -{' '}
                                    </span>
                                    <div className="zoneStats__gipClient">
                                      {client}
                                    </div>
                                  </div>
                                ),
                              )}
                            </div> : <div className="zoneStats__empty">
                              No vips in use
                            </div>}
                        </div>

                        <div className="zoneStats__right">
                          <div className="zoneStats__header">
                            Reported bad Vips
                            {row.badGips && Object.keys(row.badGips).length ? <span className="clearBadProxies" onClick={() => clearBadProxies(row)}>
                                Clear bad proxies
                              </span> : null}
                          </div>

                          {row.badGips && Object.keys(row.badGips).length ? <div className="zoneStats__badGipsList">
                              {Object.entries(row.badGips).map(
                                ([
                                  vip,
                                  { times, cooldownend, status } = {},
                                ]) => (
                                  <div className="zoneStats__badGip">
                                    <div className="zoneStats__badVip">
                                      {vip}
                                    </div>
                                    <span
                                      className={classnames(
                                        'vipStatus',
                                        status,
                                      )}
                                    />

                                    <div className="zoneStats__badVipTimes">
                                      ({times})
                                    </div>
                                    {cooldownend && (
                                      <div className="zoneStats__badVipTime">
                                        End:{' '}
                                        {moment
                                          .utc(cooldownend)
                                          .local()
                                          .format(SHORT_DATE_FORMAT)}
                                      </div>
                                    )}
                                  </div>
                                ),
                              )}
                            </div> : <span className="zoneStats__empty">
                              No data
                            </span>}
                        </div>
                      </div>}
                  </TableCell>
                </TableRow>
              </React.Fragment>)}
          </TableBody>
        </MaterialTable>
        {loading && <PageSpinner />}
        {!loading && (!zones || !zones.length) ? <EmptyTable /> : null}
      </Paper>
    </React.Fragment>;
};
