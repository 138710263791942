import React from 'react';
import { ProxiesTable } from './proxies';
import { ZonesTable } from './zones';
import './styles.scss';

export const ProxiesContainer = () => {
  return (
    <section>
      <ZonesTable />
      <ProxiesTable />
    </section>
  );
};
