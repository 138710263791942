import React, { useState, useEffect, useContext } from 'react';
import { Popconfirm } from 'antd';
import moment from 'moment';
import { useModal } from 'react-modal-hook';
import { default as MaterialTable } from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TablePagination from '@material-ui/core/TablePagination';
import {
  DATE_FORMAT,
  fetchProxies,
  removeProxy,
} from '../../services/api.service';
import { EmptyTable } from '../common/emptytable';
import { PageSpinner } from '../common/pagespinner';
import { waitFor } from '../../utils/utils';
import { EditProxyModal } from './edit.proxy.modal';
import {} from './edit.zone.modal';
import { NotificationContext } from '../../notifications.context';
import Button from '@material-ui/core/Button';

export const ProxiesTable = () => {
  const [proxies, setProxies] = useState([]);
  const [tableState, setTableState] = useState({
    page: 0,
    rowsPerPage: 25,
  });
  const [loading, setLoading] = useState(true);
  const [editingProxy, setEditingProxy] = useState(null);
  const { notify } = useContext(NotificationContext);

  const handleChangePage = (event, page) => {
    setTableState({ ...tableState, page });
  };

  const handleChangeRowsPerPage = event => {
    setTableState({ ...tableState, rowsPerPage: event.target.value });
  };

  const fetchProxyData = async () => {
    try {
      setProxies([]);
      setLoading(true);
      await waitFor(300);
      const proxies = await fetchProxies();
      setProxies(proxies);
    } catch (err) {
      notify(err, 'error');
    } finally {
      setLoading(false);
    }
  };

  const [showModal, hideModal] = useModal(
    () => (
      <EditProxyModal
        hideModal={() => {
          hideModal();
          setEditingProxy(null);
        }}
        data={editingProxy}
        onDataSaved={data => {
          setEditingProxy(null);
          if (data) {
            setLoading(true);
            fetchProxyData().then(() => notify('Proxy updated'));
          }
        }}
      />
    ),
    [editingProxy, proxies],
  );

  useEffect(() => {
    fetchProxyData();
  }, []);

  const onClickEdit = proxy => {
    setEditingProxy(proxy);
    showModal();
  };

  const onDeleteProxy = async proxy => {
    try {
      await removeProxy(proxy.id);
      setProxies(proxies.filter(p => p.id !== proxy.id));
      notify('Proxy removed');
    } catch (err) {
      notify(err, 'error');
    }
  };

  const { page, rowsPerPage } = tableState;
  return (
    <React.Fragment>
      <div className="section-head">
        <h1 className="page-heading">Proxies</h1>

        <div className="section-headRight">
          <Button
            onClick={fetchProxyData}
            style={{ marginRight: 24 }}
            disabled={loading}>
            Update proxies
          </Button>
          <Button variant="outlined" color="primary" onClick={showModal}>
            Add proxy
          </Button>
        </div>
      </div>
      <Paper
        style={{ marginTop: 24 }}
        square={true}
        elevation={1}
        className="tableContainer">
        <MaterialTable className="material-table">
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="right">Proxy Ip</TableCell>
              <TableCell align="right">Port</TableCell>
              <TableCell align="right">User</TableCell>
              <TableCell align="right">Password</TableCell>
              <TableCell align="right">Assigned</TableCell>
              <TableCell align="right">Last used</TableCell>
              <TableCell align="right">Time Reported Invalid</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Datacenter</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {proxies
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map(row => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.id}
                  </TableCell>
                  <TableCell align="right">
                    <strong>{row.ip}</strong>
                  </TableCell>
                  <TableCell align="right">{row.port}</TableCell>
                  <TableCell align="right">{row.user}</TableCell>
                  <TableCell align="right">{row.password}</TableCell>
                  <TableCell align="right">
                    <strong>{row.assigned}</strong>
                  </TableCell>
                  <TableCell align="right">{row.lastUsed}</TableCell>
                  <TableCell align="right">{row.reusedCount}</TableCell>
                  <TableCell align="right" className={row.status}>
                    {row.status}
                    {row.cooldownend ? (
                      <div>
                        Endtime:{' '}
                        {moment
                          .utc(row.cooldownend)
                          .local()
                          .format(DATE_FORMAT)}
                      </div>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell align="right">{row.dataCenter}</TableCell>
                  <TableCell align="right">
                    <div className="matTableActions">
                      <IconButton
                        aria-label="Delete"
                        onClick={() => onClickEdit(row)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <div onClick={e => e.stopPropagation()}>
                        <Popconfirm
                          placement="left"
                          title="are you sure？"
                          onConfirm={() => onDeleteProxy(row)}
                          okText="delete"
                          cancelText="cancel">
                          <IconButton aria-label="Delete">
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </Popconfirm>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </MaterialTable>
        {loading && <PageSpinner />}
        {!loading && (!proxies || !proxies.length) ? <EmptyTable /> : null}
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={proxies.length}
          rowsPerPage={tableState.rowsPerPage}
          page={tableState.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </React.Fragment>
  );
};

export default ProxiesTable;
