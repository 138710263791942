import React from 'react';
import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  modal: {
    flexGrow: 1,
  },
  modalContent: {
    color: 'white',
    padding: theme.spacing.unit * 4,
    '&:hover': {
      color: 'white',
    },
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing.unit * 4,
    paddingRight: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 2,
  },
  paper: {
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    outline: 'none',
  },
});

const BaseModalComponent = ({ children, classes, width, onClose }) => (
  <Modal
    open={true}
    onClose={onClose}
    style={{
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    }}>
    <section className={classes.paper} style={{ width: width }}>
      <div className={classes.modalContent}>{children}</div>
    </section>
  </Modal>
);

export const BaseModal = withStyles(styles)(BaseModalComponent);
