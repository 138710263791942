import React, { useState, useEffect, useContext } from 'react';
import { Popconfirm } from 'antd';
import {
  fetchClients,
  forceDisconnectClient,
  removeClient,
} from '../../services/api.service';
import { useModal } from 'react-modal-hook';
import { default as MaterialTable } from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {
  People as PeopleIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExitToApp,
} from '@material-ui/icons';
import { EmptyTable } from '../common/emptytable';
import { EditClientModal } from './edit.client.modal';
import { waitFor } from '../../utils/utils';
import { PageSpinner } from '../common/pagespinner';
import classnames from 'classnames';
import { NotificationContext } from '../../notifications.context';
import { history } from '../../routes';

const ClientsTable = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingClient, setEditingClient] = useState(null);
  const { notify } = useContext(NotificationContext);

  const fetchData = async () => {
    try {
      setClients([]);
      setLoading(true);
      await waitFor(300);
      const clients = await fetchClients();
      setClients(clients);
    } catch (err) {
      notify(err.message || err, 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const [showModal, hideModal] = useModal(
    () => (
      <EditClientModal
        hideModal={() => {
          hideModal();
          setEditingClient(null);
        }}
        notify={notify}
        data={editingClient}
        onDataSaved={data => {
          setEditingClient(null);
          if (data) {
            setLoading(true);
            fetchData().then(() => notify('Client updated'));
          }
        }}
      />
    ),
    [editingClient, clients],
  );

  const onClickEdit = proxy => {
    setEditingClient(proxy);
    showModal();
  };

  const onDeleteClient = async client => {
    try {
      await removeClient(client.id);
      setClients(clients.filter(p => p.id !== client.id));
      notify('Client removed');
    } catch (err) {
      notify(err, 'error');
    }
  };

  const displayZonesConfig = client => {
    if (client.useProxyPool) {
      return (
        <div>
          <div className="clients__zoneCfg">Shared pool</div>
          {client.useStandardProxiesInPoll && (
            <div className="clients__zoneCfgSmall">● Use standard proxies</div>
          )}
          {client.useZonesSharedWithPool && (
            <div className="clients__zoneCfgSmall">● Use Shared Zones</div>
          )}
        </div>
      );
    }
    return (
      <div>
        <div className="clients__zoneCfg">Zone name</div>
        <div className="clients__zoneCfgSmall">{client.zoneName}</div>
      </div>
    );
  };

  const onClickPeople = client => history.push(`${client.id}/employees`);

  const logoutClient = async client => {
    await forceDisconnectClient(client.apiKey);
    fetchData();
  };

  return (
    <React.Fragment>
      <div className="section-head">
        <h1 className="page-heading">Clients</h1>
        <Button variant="outlined" color="primary" onClick={showModal}>
          Add client
        </Button>
      </div>
      <Paper
        style={{ marginTop: 24 }}
        square={true}
        elevation={1}
        className="tableContainer">
        <MaterialTable className="material-table">
          <TableHead>
            <TableRow>
              <TableCell className="smallCell">Id</TableCell>
              <TableCell align="right">Company/Email</TableCell>
              <TableCell align="right">Is Online</TableCell>
              <TableCell align="right">Api key</TableCell>
              <TableCell align="right">Password</TableCell>
              <TableCell align="right" classes={{ root: 'smallCell' }}>
                Tabs in use / Total licensed tabs
              </TableCell>
              <TableCell align="right">Proxies Per Client</TableCell>
              <TableCell align="right">Zone config</TableCell>
              <TableCell align="right" className="smallCell">
                Enabled
              </TableCell>
              <TableCell align="right" className="smallCell">
                Green Mode
              </TableCell>
              <TableCell align="right">Last used</TableCell>
              <TableCell align="right">Contact</TableCell>
              <TableCell align="right" style={{ width: 200 }}>
                Address
              </TableCell>
              <TableCell align="right">Shows Advance Settings</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {clients.map(row => (
              <TableRow key={row.id}>
                <TableCell
                  component="th"
                  scope="row"
                  classes={{ root: 'smallCell' }}
                  className="smallCell">
                  {row.id}
                </TableCell>
                <TableCell align="right" className="clients__companyCell">
                  <span>{row.companyName}</span>
                  <strong>{row.email}</strong>
                </TableCell>
                <TableCell align="right">
                  <span
                    className={classnames('statusIndicator', {
                      online: row.isOnline,
                    })}
                  />
                </TableCell>
                <TableCell align="right">{row.apiKey}</TableCell>
                <TableCell align="right">{row.password}</TableCell>
                <TableCell align="right" classes={{ root: 'smallCell' }}>
                  <strong>
                    {row.tabsInUse || 0} / {row.totalLicensedTabs}
                  </strong>
                </TableCell>
                <TableCell align="right" classes={{ root: 'smallCell' }}>
                  {row.reuseCount}
                </TableCell>

                <TableCell align="right" classes={{ root: 'smallCell' }}>
                  {displayZonesConfig(row)}
                </TableCell>
                <TableCell align="right" classes={{ root: 'smallCell' }}>
                  {row.enabled ? (
                    <DoneIcon style={{ color: '#07ca10' }} />
                  ) : (
                    <NotInterestedIcon style={{ color: '#ca3d0f' }} />
                  )}
                </TableCell>

                <TableCell align="right" classes={{ root: 'smallCell' }}>
                  {row.allowGreenMode ? (
                    <DoneIcon style={{ color: '#07ca10' }} />
                  ) : (
                    <NotInterestedIcon style={{ color: '#ca3d0f' }} />
                  )}
                </TableCell>
                <TableCell align="right">{row.lastUsed}</TableCell>
                <TableCell align="right">
                  <div>{row.primaryContact}</div>
                  <div>{row.phone}</div>
                  <div>{row.website}</div>
                </TableCell>
                <TableCell align="left">
                  <div className="client-address">
                    {row.address && <span>{row.address}, &nbsp;</span>}
                    {row.city && <span>{row.city}, &nbsp;</span>}
                    {row.state && <span>{row.state}, &nbsp;</span>}
                    {row.zip && <span>{row.zip}</span>}
                  </div>
                </TableCell>
                <TableCell align="right">
                  {row.debug ? (
                    <DoneIcon style={{ color: '#07ca10' }} />
                  ) : (
                    <NotInterestedIcon style={{ color: '#ca3d0f' }} />
                  )}
                </TableCell>
                <TableCell align="right">
                  <div className="matTableActions">
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onClickPeople(row)}>
                      <PeopleIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onClickEdit(row)}>
                      <EditIcon fontSize="small" />
                    </IconButton>
                    <div onClick={e => e.stopPropagation()}>
                      <Popconfirm
                        placement="left"
                        title="are you sure？"
                        onConfirm={() => onDeleteClient(row)}
                        okText="delete"
                        cancelText="cancel">
                        <IconButton aria-label="Delete">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Popconfirm>
                    </div>
                    <div>
                      <IconButton
                        disabled={!row.isOnline}
                        aria-label="Delete"
                        onClick={() => logoutClient(row)}>
                        <ExitToApp fontSize="small" />
                      </IconButton>
                    </div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </MaterialTable>
        {loading && <PageSpinner />}
        {!loading && (!clients || !clients.length) ? <EmptyTable /> : null}
      </Paper>
    </React.Fragment>
  );
};

export default ClientsTable;
