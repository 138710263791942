export const waitFor = (delay = 1000) =>
  new Promise(resolve => setTimeout(resolve, delay));

const IP_REGEXP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;

export const isProxyValid = ({ ip, port } = {}) => {
  if (!ip || !port) {
    return false;
  }
  if (isNaN(port)) {
    return false;
  }
  if (!IP_REGEXP.test(ip)) {
    return false;
  }
  if (Number(port) <= 0 || Number(port) >= 65535) {
    return false;
  }
  return true;
};

export const trimAllValues = values =>
  Object.entries(values).reduce((acc, [key, value]) => {
    if (value && typeof value === 'string') {
      acc[key] = value.trim();
      return acc;
    }
    acc[key] = value;
    return acc;
  }, {});
