import React, { useEffect, useState } from 'react';
import {
  fetchExcludedDomainList,
  saveExcludedDomainList,
} from '../../services/api.service';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Chip from '@material-ui/core/Chip';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';

export const ExcludedDomains = () => {
  const [excludedDomainList, setExcludedDomainList] = useState([]);
  const [currentDomain, setCurrentDomain] = useState('');

  useEffect(() => {
    fetchExcludedDomainList().then(excludedDomainList => {
      setExcludedDomainList(excludedDomainList);
    });
  }, []);

  const addExcludedDomain = async domain => {
    if (!domain || !domain.trim()) {
      console.log('Invalid domain');
      return;
    }
    const newDomain = domain.trim();
    if (excludedDomainList.includes(newDomain)) {
      console.log('Domain already exists');
      return;
    }
    const newExcludedDomains = [...excludedDomainList, newDomain];
    await saveExcludedDomainList(newExcludedDomains);
    setExcludedDomainList(newExcludedDomains);
    setCurrentDomain('');
  };

  const removeExcludedDomain = async domain => {
    if (!domain || !domain.trim()) {
      return;
    }
    const targetDomain = domain.trim();
    const newExcludedDomains = excludedDomainList.filter(
      domain => domain !== targetDomain,
    );
    await saveExcludedDomainList(newExcludedDomains);
    setExcludedDomainList(newExcludedDomains);
  };

  return (
    <section className="excludedDomainList">
      <h1 className="settingsHeading">Excluded domains</h1>

      <div className="excludedDomainList__formWrapper">
        <FormControl style={{ minWidth: 200 }}>
          <InputLabel htmlFor="coolDownLimit">Add excluded domain</InputLabel>
          <Input
            id="coolDownLimit"
            value={currentDomain}
            onChange={e => setCurrentDomain(e.target.value)}
          />
        </FormControl>
        <Button
          variant="outlined"
          color="primary"
          type="submit"
          style={{ marginLeft: 12 }}
          onClick={() => addExcludedDomain(currentDomain)}>
          Add
        </Button>
      </div>

      <div className="excludedDomainList__chipWrapper">
        {excludedDomainList.map(domain => (
          <Chip
            key={domain}
            label={domain}
            className="settingChip"
            style={{ marginRight: 12, marginBottom: 8 }}
            onDelete={() => removeExcludedDomain(domain)}
            color="primary"
          />
        ))}
      </div>
    </section>
  );
};
