import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import { useForm, useField } from 'react-final-form-hooks';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { login as apiLogin } from '../../services/api.service';
import { UserContext } from '../../user.context';

const styles = theme => ({
  main: {
    width: '100%',
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    width: 340,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 0,

    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme
      .spacing.unit * 3}px`,
  },
  form: {
    marginTop: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
  heading: {
    margin: theme.spacing.unit * 2,
  },
  serverError: {
    color: 'red',
    margin: '12px 0',
  },
});

const validate = values => {
  const errors = {};
  if (!values.login) {
    errors.login = 'Required';
  }
  if (!values.password) {
    errors.password = 'Required';
  }
  return errors;
};

function SignIn({ classes }) {
  const [processing, setProcessing] = useState(false);
  const [serverError, setServerError] = useState('');
  const { setUser } = useContext(UserContext);

  const onSubmit = async ({ login, password }) => {
    setProcessing(true);
    try {
      const user = await apiLogin(login.trim(), password.trim());
      setUser(user);
    } catch (err) {
      console.log(err);
      setServerError('Auth failed');
    } finally {
      setProcessing(false);
    }
    setProcessing(false);
  };

  const { form, handleSubmit } = useForm({
    initialValues: {
      login: '',
      password: '',
    },
    onSubmit,
    validate,
  });
  const login = useField('login', form);
  const password = useField('password', form);

  return (
    <div className={classes.main}>
      <CssBaseline />
      <Paper
        className={classes.paper}
        style={{ overflow: 'hidden' }}
        elevation={1}>
        <h1 className={classes.heading}>Login</h1>
        <form className={classes.form} onSubmit={handleSubmit}>
          <TextField
            id="login"
            label="Login"
            fullWidth
            autoComplete="disabled"
            disabled={processing}
            {...login.input}
          />
          <TextField
            label="Password"
            id="password"
            type="password"
            style={{ marginTop: 14 }}
            fullWidth
            disabled={processing}
            {...password.input}
          />
          {serverError && <p className={classes.serverError}>{serverError}</p>}
          <Button
            type="submit"
            fullWidth
            variant="outlined"
            color="primary"
            disabled={processing}
            className={classes.submit}>
            Login
          </Button>
        </form>
      </Paper>
    </div>
  );
}

export const LoginPage = withStyles(styles)(SignIn);
