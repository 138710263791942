import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import Clients from '../../components/clients/clients';
import { Settings } from '../../components/settings/settings';
import AppBar from '../../components/common/appbar';
import { UserContext } from '../../user.context';
import EmployeesTable from '../../components/employees/employees';
import './main.page.scss';
import { ProxiesContainer } from '../../components/proxies/proxies.container';

const MainPage = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    return null;
  }
  return (
    <Layout>
      <AppBar />
      <Layout>
        {user.role === 'admin' ? (
          <Layout
            style={{ padding: '0 24px 24px' }}
            className="main-inner-content">
            <Route path="" exact component={Clients} />
            <Route path="/:id/employees" exact component={EmployeesTable} />
            <Route path="/proxies" component={ProxiesContainer} />
            <Route path="/settings" component={Settings} />
          </Layout>
        ) : (
          <Layout
            style={{ padding: '0 24px 24px' }}
            className="main-inner-content">
            <EmployeesTable />
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

export default MainPage;
