import React from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { BaseModal } from '../common/modal';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { trimAllValues, waitFor } from '../../utils/utils';
import { addOrUpdateClient, checkZoneName } from '../../services/api.service';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const styles = () => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 12,
    paddingTop: 32,
  },
  footerButton: {
    minWidth: 160,
    marginLeft: 12,
  },
  textField: {
    margin: '8px !important',
    width: 198,
  },
  select: {
    width: 198,
    margin: '8px 8px',
  },
});

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required';
  }
  if (!values.companyName) {
    errors.companyName = 'Required';
  }
  if (!values.useProxyPool) {
    if (!values.zoneName) {
      errors.zoneName = 'Zone is Required';
    }
  }

  return errors;
};

const initialValues = {
  enabled: true,
  allowGreenMode: true,
  reuseCount: 1,
  totalLicensedTabs: 1,
  useProxyPool: true,
  useZonesSharedWithPool: true,
  useStandardProxiesInPoll: true,
  zoneName: '',
};

export const EditClientModalComponent = ({
  hideModal,
  classes,
  data = {},
  onDataSaved,
  notify = () => {},
}) => {
  const { form, handleSubmit, submitting, errors } = useForm({
    initialValues: { ...initialValues, ...data },
    onSubmit: async values => {
      await waitFor(300);
      try {
        if (values.useProxyPool) {
          values.zoneName = '';
        } else {
          try {
            const isZoneExist = await checkZoneName(values.zoneName);
            if (!isZoneExist) {
              errors.zoneName = 'Zone is not found';
              return;
            }
          } catch (err) {
            errors.zoneName = 'Zone is not found';
          }
        }
        const rez = await addOrUpdateClient(trimAllValues(values));
        hideModal();
        onDataSaved(rez);
      } catch (err) {
        console.log('err', err);
        notify(err, 'error');
      }
    },
    validate,
  });

  const email = useField('email', form);
  const companyName = useField('companyName', form);
  const reuseCount = useField('reuseCount', form);
  const totalLicensedTabs = useField('totalLicensedTabs', form);
  const enabled = useField('enabled', form);
  const allowGreenMode = useField('allowGreenMode', form);
  const password = useField('password', form);
  const primaryContact = useField('primaryContact', form);
  const address = useField('address', form);
  const city = useField('city', form);
  const phone = useField('phone', form);
  const state = useField('state', form);
  const website = useField('website', form);
  const zip = useField('zip', form);
  const debug = useField('debug', form);
  const lockInboxSelect = useField('lockInboxSelect', form);

  // In the client there would need to be a setting to tell which proxy option the client should use either use the “Pool” or  explicitly use a particular zone.
  //   CLIENT CONFIG
  // OPTION 1  = “Use Proxy Pool”
  // 	Sub Item Checkbox  =  “Use The Zones Shared with the Pool”
  // 			Sub Item Checkbox = “Use Standard Proxies in Pool”
  // 	OPTION 2 = “Use specific zone”   (if option 2 is checked then they need to
  // also enter a value to tell us which zone to use, then this
  // client will always use that zone.
  //   Sub Item Text Box  =  “What Zone should this client use?”
  const useProxyPool = useField('useProxyPool', form);
  const useZonesSharedWithPool = useField('useZonesSharedWithPool', form);
  const useStandardProxiesInPoll = useField('useStandardProxiesInPoll', form);
  const zoneName = useField('zoneName', form);

  const getFieldError = field =>
    field.meta.touched && field.meta.error ? field.meta.error : false;

  return (
    <BaseModal width={740} onClose={hideModal}>
      <React.Fragment>
        <h2>Edit Client</h2>
        <form onSubmit={handleSubmit}>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(email)}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" {...email.input} />
            {getFieldError(email) && (
              <FormHelperText id="email">{getFieldError(email)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(companyName)}>
            <InputLabel htmlFor="companyName">Company name</InputLabel>
            <Input id="companyName" {...companyName.input} />
            {getFieldError(companyName) && (
              <FormHelperText id="companyName">
                {getFieldError(companyName)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(reuseCount)}>
            <InputLabel htmlFor="reuseCount">Proxies Per Client</InputLabel>
            <Input type="number" id="reuseCount" {...reuseCount.input} />
            {getFieldError(reuseCount) && (
              <FormHelperText id="reuseCount">
                {getFieldError(reuseCount)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(enabled)}>
            <InputLabel htmlFor="enabled">Status</InputLabel>
            <Select {...enabled.input} id="enabled">
              <MenuItem value={true}>Enabled</MenuItem>
              <MenuItem value={false}>Disabled</MenuItem>
            </Select>
            {getFieldError(enabled) && (
              <FormHelperText id="enabled">
                {getFieldError(enabled)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(allowGreenMode)}>
            <InputLabel htmlFor="allowGreenMode">Green Mode</InputLabel>
            <Select {...allowGreenMode.input} id="allowGreenMode">
              <MenuItem value={true}>Enabled</MenuItem>
              <MenuItem value={false}>Disabled</MenuItem>
            </Select>
            {getFieldError(allowGreenMode) && (
              <FormHelperText id="allowGreenMode">
                {getFieldError(allowGreenMode)}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(totalLicensedTabs)}>
            <InputLabel htmlFor="totalLicensedTabs">
              Total Licensed Tabs
            </InputLabel>
            <Input
              type="number"
              id="totalLicensedTabs"
              {...totalLicensedTabs.input}
            />
            {getFieldError(totalLicensedTabs) && (
              <FormHelperText id="totalLicensedTabs">
                {getFieldError(totalLicensedTabs)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(password)}>
            <InputLabel htmlFor="password">Password</InputLabel>
            <Input id="password" {...password.input} />
            {getFieldError(password) && (
              <FormHelperText id="password">
                {getFieldError(password)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(primaryContact)}>
            <InputLabel htmlFor="primaryContact">Primary Contact</InputLabel>
            <Input id="primaryContact" {...primaryContact.input} />
            {getFieldError(primaryContact) && (
              <FormHelperText id="primaryContact">
                {getFieldError(primaryContact)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(address)}>
            <InputLabel htmlFor="address">Address</InputLabel>
            <Input id="address" {...address.input} />
            {getFieldError(address) && (
              <FormHelperText id="address">
                {getFieldError(address)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(city)}>
            <InputLabel htmlFor="city">City</InputLabel>
            <Input id="city" {...city.input} />
            {getFieldError(city) && (
              <FormHelperText id="city">{getFieldError(city)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(phone)}>
            <InputLabel htmlFor="phone">Phone</InputLabel>
            <Input id="phone" {...phone.input} />
            {getFieldError(phone) && (
              <FormHelperText id="phone">{getFieldError(phone)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(state)}>
            <InputLabel htmlFor="state">State</InputLabel>
            <Input id="state" {...state.input} />
            {getFieldError(state) && (
              <FormHelperText id="state">{getFieldError(state)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(website)}>
            <InputLabel htmlFor="website">Website</InputLabel>
            <Input id="website" {...website.input} />
            {getFieldError(website) && (
              <FormHelperText id="website">
                {getFieldError(website)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(zip)}>
            <InputLabel htmlFor="zip">Zip</InputLabel>
            <Input id="zip" {...zip.input} />
            {getFieldError(zip) && (
              <FormHelperText id="zip">{getFieldError(zip)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(debug)}>
            <InputLabel htmlFor="debug">Shows Advance Settings</InputLabel>
            <Select {...debug.input} id="debug">
              <MenuItem value={true}>Enabled</MenuItem>
              <MenuItem value={false}>Disabled</MenuItem>
            </Select>
            {getFieldError(debug) && (
              <FormHelperText id="debug">{getFieldError(debug)}</FormHelperText>
            )}
          </FormControl>

          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(useProxyPool)}>
            <InputLabel htmlFor="useProxyPool">Proxies type config</InputLabel>
            <Select {...useProxyPool.input} id="useProxyPool">
              <MenuItem value={true}>Use Proxy Pool</MenuItem>
              <MenuItem value={false}>Use specific zone</MenuItem>
            </Select>
            {getFieldError(useProxyPool) && (
              <FormHelperText id="useProxyPool">
                {getFieldError(useProxyPool)}
              </FormHelperText>
            )}
          </FormControl>

          {!!useProxyPool.input.value ? (
            <div style={{ padding: '0 8px' }}>
              <FormControl
                disabled={submitting}
                className={classes.checkboxField}
                error={!!getFieldError(useZonesSharedWithPool)}>
                <FormControlLabel
                  disabled={submitting}
                  control={
                    <Checkbox
                      {...useZonesSharedWithPool.input}
                      value={`${useZonesSharedWithPool.input.value}`}
                      checked={useZonesSharedWithPool.input.value}
                      color="primary"
                    />
                  }
                  label="Use The Zones Shared with the Pool"
                />
                {getFieldError(useZonesSharedWithPool) && (
                  <FormHelperText id="useZonesSharedWithPool">
                    {getFieldError(useZonesSharedWithPool)}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                disabled={submitting}
                className={classes.checkboxField}
                error={!!getFieldError(useStandardProxiesInPoll)}>
                <FormControlLabel
                  disabled={submitting}
                  control={
                    <Checkbox
                      {...useStandardProxiesInPoll.input}
                      value={`${useStandardProxiesInPoll.input.value}`}
                      checked={useStandardProxiesInPoll.input.value}
                      color="primary"
                    />
                  }
                  label="Use Standard Proxies in Pool"
                />
                {getFieldError(useStandardProxiesInPoll) && (
                  <FormHelperText id="useStandardProxiesInPoll">
                    {getFieldError(useStandardProxiesInPoll)}
                  </FormHelperText>
                )}
              </FormControl>
            </div>
          ) : (
            <FormControl
              disabled={submitting}
              className={classes.textField}
              error={!!getFieldError(zoneName)}>
              <InputLabel htmlFor="zoneName">Zone name</InputLabel>
              <Input type="text" id="zoneName" {...zoneName.input} />
              {getFieldError(zoneName) && (
                <FormHelperText id="zoneName">
                  {getFieldError(zoneName)}
                </FormHelperText>
              )}
            </FormControl>
          )}

          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(lockInboxSelect)}>
            <InputLabel htmlFor="lockInboxSelect">Lock Inbox Dropdown</InputLabel>
            <Select {...lockInboxSelect.input} id="lockInboxSelect">
              <MenuItem value={true}>True</MenuItem>
              <MenuItem value={false}>False</MenuItem>
            </Select>
            {getFieldError(lockInboxSelect) && (
              <FormHelperText id="lockInboxSelect">
                {getFieldError(lockInboxSelect)}
              </FormHelperText>
            )}
          </FormControl>

          <section className={classes.modalFooter}>
            <Button
              disabled={submitting}
              type="button"
              className={classes.footerButton}
              onClick={hideModal}>
              Close
            </Button>
            <Button
              disabled={submitting}
              className={classes.footerButton}
              variant="outlined"
              color="primary"
              type="submit">
              Save
            </Button>
          </section>
        </form>
      </React.Fragment>
    </BaseModal>
  );
};

export const EditClientModal = withStyles(styles)(EditClientModalComponent);
