import React, { useState, useEffect } from 'react';
import { Popconfirm, message } from 'antd';
import {
  addMachineType,
  fetchMachineTypes,
  removeMachineType,
  updateMachineTypeStatus,
} from '../../services/api.service';
import './settings.styles.scss';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Checkbox from '@material-ui/core/Checkbox';
import { default as MaterialTable } from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { EmptyTable } from '../common/emptytable';

export const MachineTypes = () => {
  const [machines, setMachines] = useState([]);
  const [newMachine, setNewMachine] = useState('');

  useEffect(() => {
    fetchMachineTypes().then(setMachines);
  }, []);

  const changeMachineStatus = record => {
    updateMachineTypeStatus(record.id, !record.isActive);
    setMachines(
      machines.map(machineType => ({
        ...machineType,
        isActive:
          machineType.id === record.id
            ? !machineType.isActive
            : machineType.isActive,
      })),
    );
  };

  const onAddNewMachineType = () => {
    if (!newMachine || !newMachine.trim()) {
      return;
    }
    addMachineType({ name: newMachine })
      .then(createdMachine => {
        setMachines([...machines, createdMachine]);
        message.success('New machine type added');
      })
      .catch(err => message.error(`Error - ${err.message}`))
      .finally(() => setNewMachine(''));
  };

  const onDeleteConfirm = record => {
    removeMachineType(record.id).then(() => {
      setMachines(machines.filter(item => item.id !== record.id));
      message.info('Machine type removed');
    });
  };

  return (
    <Paper square={true} elevation={1}>
      <MaterialTable className="material-table settings-table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Is Active</TableCell>
            <TableCell align="left">Name</TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {machines.map(row => (
            <TableRow key={row.id}>
              <TableCell align="right">
                <Checkbox
                  color="primary"
                  className="useragent-checkbox"
                  onChange={() => changeMachineStatus(row)}
                  checked={!!row.isActive}
                />
              </TableCell>
              <TableCell align="left">{row.name}</TableCell>
              <TableCell align="right">
                <div className="matTableActions">
                  <div onClick={e => e.stopPropagation()}>
                    <Popconfirm
                      placement="left"
                      title="are you sure？"
                      onConfirm={() => onDeleteConfirm(row)}
                      okText="delete"
                      cancelText="cancel">
                      <IconButton aria-label="Delete">
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    </Popconfirm>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>

      </MaterialTable>
      {!machines || (!machines.length && <EmptyTable />)}

      <div className="add-useragent-form">
        <Input
          placeholder="Add new machine"
          style={{ width: '100%' }}
          value={newMachine}
          onKeyPress={ev => {
            if (ev.key === 'Enter') {
              ev.preventDefault();
              onAddNewMachineType();
            }
          }}
          onChange={e => setNewMachine(e.target.value)}
        />
        <Button
          type="primary"
          variant={'outlined'}
          className="add-useragent-button"
          onClick={onAddNewMachineType}>
          Add machine
        </Button>
      </div>
    </Paper>
  );
};
