import React from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { BaseModal } from '../common/modal';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { waitFor } from '../../utils/utils';
import { addOrUpdateEmployee } from '../../services/api.service';

const styles = () => ({
  modalFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingBottom: 12,
    paddingTop: 32,
  },
  footerButton: {
    minWidth: 160,
    marginLeft: 12,
  },
  textField: {
    margin: '8px !important',
    width: 198,
  },
  select: {
    width: 198,
    margin: '8px 8px',
  },
});

const validate = values => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = 'Required';
  }
  if (!values.lastName) {
    errors.lastName = 'Required';
  }
  if (!values.email) {
    errors.email = 'Required';
  }
  return errors;
};

const initialValues = {};

export const EditEmployeeModalComponent = ({
  hideModal,
  classes,
  data = {},
  clientId,
  onDataSaved,
  notify = () => {},
}) => {
  const { form, handleSubmit, submitting } = useForm({
    initialValues: { ...initialValues, ...data },
    onSubmit: async values => {
      await waitFor(1000);
      try {
        const rez = await addOrUpdateEmployee({ ...values, clientId });
        hideModal();
        onDataSaved(rez);
      } catch (err) {
        console.log('err', err);
        notify(err, 'error');
      }
    },
    validate,
  });

  const firstName = useField('firstName', form);
  const lastName = useField('lastName', form);
  const email = useField('email', form);
  const address = useField('address', form);
  const city = useField('city', form);
  const state = useField('state', form);
  const zip = useField('zip', form);
  const sessionPhone = useField('sessionPhone', form);
  const forwardingPhone = useField('forwardingPhone', form);
  const siteLogin = useField('siteLogin', form);
  const sitePassword = useField('sitePassword', form);
  const chatRoom = useField('chatRoom', form);

  const getFieldError = field =>
    field.meta.touched && field.meta.error ? field.meta.error : false;

  return (
    <BaseModal width={500} onClose={hideModal}>
      <React.Fragment>
        <h2>Edit Employee</h2>
        <form onSubmit={handleSubmit}>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(firstName)}>
            <InputLabel htmlFor="firstName">First name</InputLabel>
            <Input id="firstName" {...firstName.input} />
            {getFieldError(firstName) && (
              <FormHelperText id="firstName">
                {getFieldError(firstName)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(lastName)}>
            <InputLabel htmlFor="lastName">Last name</InputLabel>
            <Input id="lastName" {...lastName.input} />
            {getFieldError(lastName) && (
              <FormHelperText id="lastName">
                {getFieldError(lastName)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(email)}>
            <InputLabel htmlFor="email">Email</InputLabel>
            <Input id="email" {...email.input} />
            {getFieldError(email) && (
              <FormHelperText id="email">{getFieldError(email)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(address)}>
            <InputLabel htmlFor="address">Address</InputLabel>
            <Input id="email" {...address.input} />
            {getFieldError(address) && (
              <FormHelperText id="address">
                {getFieldError(address)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(city)}>
            <InputLabel htmlFor="city">City</InputLabel>
            <Input id="city" {...city.input} />
            {getFieldError(city) && (
              <FormHelperText id="city">{getFieldError(city)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(state)}>
            <InputLabel htmlFor="state">State</InputLabel>
            <Input id="state" {...state.input} />
            {getFieldError(state) && (
              <FormHelperText id="state">{getFieldError(state)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(zip)}>
            <InputLabel htmlFor="zip">Zip</InputLabel>
            <Input id="zip" {...zip.input} />
            {getFieldError(zip) && (
              <FormHelperText id="zip">{getFieldError(zip)}</FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(sessionPhone)}>
            <InputLabel htmlFor="sessionPhone">Session phone</InputLabel>
            <Input id="sessionPhone" {...sessionPhone.input} />
            {getFieldError(sessionPhone) && (
              <FormHelperText id="sessionPhone">
                {getFieldError(sessionPhone)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(forwardingPhone)}>
            <InputLabel htmlFor="forwardingPhone">Virtual Phone</InputLabel>
            <Input id="forwardingPhone" {...forwardingPhone.input} />
            {getFieldError(forwardingPhone) && (
              <FormHelperText id="forwardingPhone">
                {getFieldError(forwardingPhone)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(siteLogin)}>
            <InputLabel htmlFor="siteLogin">Site login</InputLabel>
            <Input id="siteLogin" {...siteLogin.input} />
            {getFieldError(siteLogin) && (
              <FormHelperText id="siteLogin">
                {getFieldError(siteLogin)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(sitePassword)}>
            <InputLabel htmlFor="sitePassword">Site password</InputLabel>
            <Input id="sitePassword" {...sitePassword.input} />
            {getFieldError(sitePassword) && (
              <FormHelperText id="sitePassword">
                {getFieldError(sitePassword)}
              </FormHelperText>
            )}
          </FormControl>
          <FormControl
            disabled={submitting}
            className={classes.textField}
            error={!!getFieldError(chatRoom)}>
            <InputLabel htmlFor="chatRoom">Chat Room</InputLabel>
            <Input id="chatRoom" {...chatRoom.input} />
            {getFieldError(chatRoom) && (
              <FormHelperText id="chatRoom">
                {getFieldError(chatRoom)}
              </FormHelperText>
            )}
          </FormControl>
          <section className={classes.modalFooter}>
            <Button
              disabled={submitting}
              type="button"
              className={classes.footerButton}
              onClick={hideModal}>
              Close
            </Button>
            <Button
              disabled={submitting}
              className={classes.footerButton}
              variant="outlined"
              color="primary"
              type="submit">
              Save
            </Button>
          </section>
        </form>
      </React.Fragment>
    </BaseModal>
  );
};

export const EditEmployeeModal = withStyles(styles)(EditEmployeeModalComponent);
