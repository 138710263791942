import React from 'react';
import MuiThemeProvider from '@material-ui/core/es/styles/MuiThemeProvider';
import { theme } from './theme';
import { NotificationContextProvider } from './notifications.context';
import { UserContextProvider } from './user.context';

export const RootWrapper = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <NotificationContextProvider>
        <UserContextProvider>{props.children}</UserContextProvider>
      </NotificationContextProvider>
    </MuiThemeProvider>
  );
};
